<template>
  <card shadow type="secondary">
    <template #header>
      <div class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-8 text-start">
            <h5 class="mb-0">{{ userRole === "EMPLOYEE" ? $t("My Profile") : $t("Employee Details") }}</h5>
          </div>
          <div v-if="['SYSTEM_USER', 'EMPLOYEE'].includes(userRole)" class="col-4 text-end">
            <router-link class="btn btn-sm btn-primary" :to="$store.state.user.claims.role === 'EMPLOYEE' ? '/profile/edit' : '/employees/'+$route.params.id+'/edit'">
              {{ $t("Edit") }}</router-link>
          </div>
        </div>
      </div>
    </template>

    <div>
      <div class="fw-bolder text-uppercase text-muted mt-4 text-start">{{ userRole === "EMPLOYEE" ? $t("Personal Details") : $t("Employee Details") }}</div>
      <hr class="m-0">
      <div class="pl-lg-4 text-start">
        <div class="row">
          <div class="col-md-6 pt-2">
            <display-label
                label="First name"
                :value="employeeData.firstName"
            ></display-label>
          </div>
          <div class="col-md-6 pt-2">
            <display-label
                label="Last name"
                :value="employeeData.lastName"
            ></display-label>
          </div>
          <div class="col-md-6 pt-2">
            <div class="form-group has-label">
              <label class="form-label fw-bold m-0" aria-label="Identity card number">{{ $t("Identity card number") }}</label>
              <div class="text-black-50">
                {{ employeeData.idCard }}

                <a v-if="docRefs.hasOwnProperty('identity-front')" class="btn btn-sm" target="_blank" :href="docRefs['identity-front']" ><i class="fa fa-download"></i>
                  {{ docRefs.hasOwnProperty('identity-back') ? $t('Front') : '' }}
                </a>
                <a v-if="docRefs.hasOwnProperty('identity-back')" class="btn btn-sm" target="_blank" :href="docRefs['identity-back']"><i class="fa fa-download"> </i>
                  {{ $t("Back") }}</a>

              </div>
            </div>
          </div>
          <div class="col-md-6 pt-2">
            <div class="form-group has-label">
              <label class="form-label fw-bold m-0" aria-label="Registration number">{{ $t("Registration number") }}</label>
              <div class="text-black-50">
                {{ employeeData.registrationNumber }}
                <a v-if="docRefs.hasOwnProperty('registration')" class="btn btn-sm" target="_blank" :href="docRefs['registration']"><i class="fa fa-download"></i></a>
              </div>
            </div>
          </div>
          <div class="col-md-6 pt-2">
            <div class="form-group has-label">
              <label class="form-label fw-bold m-0" aria-label="Registration number expiration date">{{ $t("Registration number expiration date") }}</label>
              <div v-if="employeeData && Object.hasOwn(employeeData, 'registrationExpirationDate')" class="text-black-50">
                {{ moment(employeeData.registrationExpirationDate).format("DD/MM/YYYY") }}
              </div>
            </div>
          </div>
          <div class="col-md-6 pt-2">
            <display-label
                label="IBAN"
                :value="employeeData.iban"
            ></display-label>
          </div>
          <div class="col-md-6 pt-2">
            <display-label
                label="Bank name"
                :value="employeeData.bankName"
            ></display-label>
          </div>
          <div class="col-md-6 pt-2">
            <display-label
                label="Tax identification number"
                :value="employeeData.tiNumber"
            ></display-label>
          </div>
          <div v-if="docRefs.hasOwnProperty('registry')" class="col-md-6 pt-2">
            <label class="form-label fw-bold m-0" aria-label="Registration number">{{ $t("registry") }}</label>
            <div class="text-black-50">
              <a  class="btn btn-sm" target="_blank" :href="docRefs['registry']"><i class="fa fa-download"></i></a>
            </div>
          </div>

          <div v-if="userRole !== 'CLIENT'" class="col-md-6">
            <display-label
                label="Preferred language"
                :value="$t(employeeData.hasOwnProperty('lang') && employeeData.lang != null ? employeeData.lang : '')"
            ></display-label>
          </div>
          <div v-if="userRole !== 'CLIENT'" class="col-md-6 pt-2">
            <display-label
                label="Created at:"
                :value="employeeData.createdAt"
            ></display-label>
          </div>
          <div v-if="userRole === 'SYSTEM_USER' " class="col-md-12 pt-2">
            <display-label
                label="Description:"
                :value="employeeData.description"
            ></display-label>
          </div>
        </div>
      </div>

      <div class="fw-bolder text-uppercase text-muted mt-4 text-start">{{ $t("Contact Details") }}</div>
      <hr class="m-0">
      <div class="pl-lg-4 text-start">
        <div class="row">
          <div class="col-md-6 pt-2">
            <display-label
                label="Contact email:"
                :value="employeeData.email"
            ></display-label>
          </div>
          <div class="col-md-6">
            <display-label
                label="Contact Phone:"
                :value="employeeData.phoneNumber"
            ></display-label>
          </div>
        </div>
      </div>

      <div v-if="userRole !== 'CLIENT'" class="pl-lg-4 text-start">
        <div class="fw-bolder text-uppercase text-muted mt-4 text-start mt-4">{{ $t("Positions") }}</div>
        <hr class="m-0">
        <div>
          <div v-for="item in positions" :key="item" class="badge bg-gradient-primary m-1" > {{ item.name }}</div>
        </div>
      </div>
<!--      <div class="pl-lg-4 text-start">-->
<!--        <div class="fw-bolder text-uppercase text-muted mt-4 text-start mt-4">Billing Address</div>-->
<!--        <hr class="m-0">-->
<!--        <div class="row">-->
<!--          <div class="col-md-6 pt-2">-->
<!--            <display-label-->
<!--                label="Street:"-->
<!--                :value="employeeData.hasOwnProperty('address') ? employeeData.address.street : ''"-->
<!--            ></display-label>-->
<!--          </div>-->
<!--          <div class="col-md-6 pt-2">-->
<!--            <display-label-->
<!--                label="Street line 2:"-->
<!--                :value="employeeData.hasOwnProperty('address') ? employeeData.address.street2 : ''"-->
<!--            ></display-label>-->
<!--          </div>-->
<!--          <div class="col-md-4 pt-2">-->
<!--            <display-label-->
<!--                label="City:"-->
<!--                :value="employeeData.hasOwnProperty('address') ? employeeData.address.city : ''"-->
<!--            ></display-label>-->
<!--          </div>-->
<!--          <div class="col-md-4 pt-2">-->
<!--            <display-label-->
<!--                label="Country:"-->
<!--                :value="employeeData.hasOwnProperty('address') ? employeeData.address.countryCode : ''"-->
<!--            ></display-label>-->
<!--          </div>-->
<!--          <div class="col-md-4 pt-2">-->
<!--            <display-label-->
<!--                label="Postal code:"-->
<!--                :value="employeeData.hasOwnProperty('address') ? employeeData.address.postalCode : ''"-->
<!--            ></display-label>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </card>
</template>
<script>
import DisplayLabel from "@/components/DisplayLabel"
import {getDownloadURL, getStorage, ref} from "firebase/storage"
import moment from "moment"
export default {
  name: "employee-details-card",
  components: {DisplayLabel},
  props: {
    employeeData: {
      type: Object,
      description: "Employee data",
    },
  },
  data() {
    return {
      docRefs: [],
      isDisabled: true,
      alertMessage: "",
      loadingProcess: "",
      model: {
        address: {
          street: "",
          countryCode: "",
          city: "",
          lat: 0,
          long: 0 ,
          postalCode: "",
          street2: "",
        }
      },
      positions: []
    }
  },
  computed: {
    moment() {
      return moment
    },
    userRole(){
      return this.$store.state.user.claims.role ?? null
    },
  },
  watch: {
    employeeData(newVal) {
      this.model = newVal
      if(newVal.hasOwnProperty("positionIds")) {
        this.mapPositions()
        console.log("mapPositions done")
        console.log("employeeData", this.model)
      }
    },
  },
  mounted() {
    console.log(this.$route.params)
    console.log("employeeData", this.employeeData)
    this.mapPositions()

    if (this.userRole === "CLIENT"){
      this.getDocReferences()
    }
  },
  methods: {
    async getDocReferences() {
      const docs = ["identity-front", "identity-back", "registration", "registry"]
      for (let i = 0; i < docs.length; i++) {
        const name = docs[i]
        try {
          console.log("nameee", name)
          this.docRefs[name] = await this.downloadDoc(name)
        } catch (error) {
          console.error(name, error)
        }
      }
    },
    async downloadDoc(name) {
      const storage = getStorage()
      const idPathReference = ref(storage, "employees/" + this.employeeData.id + "/" + name)
      return await getDownloadURL(idPathReference)
    },
    async mapPositions(){
      if (!this.employeeData.hasOwnProperty("positionIds")){
        return
      }
      let positions = await this.$store.dispatch("fetchAllPositions")

      this.employeeData.positionIds.forEach(positionsId => {
        let selected = positions.find(pos => pos.id === positionsId)

        if (selected ?? false) {
          this.positions.push(selected)
        }
      })
    }
  },
}
</script>
<style></style>
