<template>
  <div>
  <card shadow type="secondary">
    <template #header>
      <div class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-8 text-start">
            <h5 class="mb-0">{{ $t("Filter by") }}</h5>
          </div>
          <!--              <div class="col-4 text-end">-->
          <!--                <base-button tag="a" :href="'/employees/'+$route.params.id+'/edit'" type="primary" class="btn btn-sm btn-primary">-->
          <!--                  Edit-->
          <!--                </base-button>-->
          <!--              </div>-->
        </div>
      </div>
    </template>

    <div class="row text-start">
<!--      <div v-if="userRole === 'SYSTEM_USER'" class="col-md-4">-->
<!--        <div class="form-group has-label">-->
<!--          <label class="form-label">{{ $t("Employee") }}</label>-->
<!--          <multiselect-->
<!--              v-model="model.employee"-->
<!--              :show-labels="false"-->
<!--              label="name"-->
<!--              track-by="id"-->
<!--              :placeholder="$t('Select client')"-->
<!--              :options="employees" :multiple="false">-->
<!--          </multiselect>-->
<!--        </div>-->
<!--      </div>-->
      <div class="col-md-4">
        <div class="form-group has-label">
          <label class="form-label">{{ $t("Status") }}</label>
          <multiselect
              v-model="model.statuses"
              :show-labels="false"
              label="label"
              track-by="key"
              :placeholder="$t('Select status')"
              :options="statuses" :multiple="true">
          </multiselect>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="col float-end">
        <submit-button
            :alert-message="errorMessage"
            :state="loadingProcess"
            @clicked="getInvoices(null, true)">
          {{ $t("Search") }}
        </submit-button>
      </div>
    </template>

  </card>
  <br>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col-6" :class="{'col-sm-12': userRole !== 'SYSTEM_USER'}">
          <h5 class="mb-0 text-start" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h5>
        </div>
        <div v-if="userRole === 'SYSTEM_USER'" class="col-6 text-end top-0">
          <router-link :to="'/invoices/new'" class="btn btn-sm btn-primary mt-1 mb-1">
            <span class="nav-link-text">{{ $t("New Invoice") }}</span>
          </router-link>
        </div>
<!--        <div class="col text-end">-->
<!--          <base-button tag="a" href="/requests/new" type="primary" size="sm">Add New</base-button>-->
<!--        </div>-->
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        v-model:loading="loading"
        :columns="columns"
        class="table align-items-center table-flush text-start"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
        :sort-by="sortBy"
        :sort-direction="sortDirection"
        :loading="loading"
        :list-full="listFull"
        :paginated="true"
        @sort="getInvoices"
        @fetch-more="getInvoices"
      >
<!--        <template #columns>-->
<!--          <th>Ref</th>-->
<!--          <th v-if="!hasUser">Client</th>-->
<!--          <th>Position</th>-->
<!--          <th>Status</th>-->
<!--&lt;!&ndash;          <th>Payment Status</th>&ndash;&gt;-->
<!--          <th>Start Date</th>-->
<!--          <th>Date Created</th>-->
<!--          <th></th>-->
<!--        </template>-->

        <template #default="row">
          <td>
<!--            <a-->
<!--                :href="'/invoices/' + row.item.id"-->
<!--            >-->
              <span class="name mb-0 text-sm">{{ row.item.invoiceRef ?? "No Ref" }}</span>
<!--            </a>-->

          </td>
          <td v-if="userRole === 'SYSTEM_USER'" class="budget">
            <a
              :href="'/employees/' + row.item.employeeId + '/info'"
              ><span class="name mb-0 text-sm">{{ row.item.employeeName }}</span></a>
          </td>
          <td class="fw-bold">
            <span v-html="row.item.products[0].name"></span>
          </td>
          <td>
            <div class="badge p-1 font-size-xs" :class="getStatusClass(row.item.status)">
              <span class="status">{{ $t(row.item.status) }}</span>
            </div>
          </td>
<!--          <td>-->
<!--            <div class="badge p-1 font-size-xs" :class="getStatusClass(row.item.paymentStatus)">-->
<!--              <span class="status">{{ $t(row.item.paymentStatus) }}</span>-->
<!--            </div>-->
<!--          </td>-->
          <td>
            <div class="name mb-0 text-md-left">
                {{ row.item.amount.total/100+'€' }}
              </div>
          </td>
          <td>
            <div class="d-flex align-items-center">
              <!--              <span class="completion mr-2">{{ row.item.completion }}%</span>-->
              <!--              <div>-->
              <!--                <base-progress-->
              <!--                  :type="row.item.statusType"-->
              <!--                  :show-percentage="false"-->
              <!--                  class="pt-0"-->
              <!--                  :value="row.item.completion"-->
              <!--                />-->
              <!--              </div>-->
              <div class="name mb-0 text-md-left">{{ row.item.createdAt }}</div>
            </div>
          </td>

          <td class="text-end">
<!--            <base-button-->
<!--              tag="a"-->
<!--              :href="'/requests/' + row.item.id"-->
<!--              class="bg-gradient-green border-0 me-2 text-white btn-sm"-->
<!--              size="md"-->
<!--              >-->
<!--              View-->
<!--            </base-button>-->
            <invoice-actions :invoice-data="row.item" @update-status="updateStatus"></invoice-actions>

          </td>
        </template>
      </base-table>
    </div>

    <!--    <div-->
    <!--      class="card-footer d-flex justify-content-end"-->
    <!--      :class="type === 'dark' ? 'bg-transparent' : ''"-->
    <!--    >-->
    <!--      <base-pagination total="30"></base-pagination>-->
    <!--    </div>-->
  </div>
  </div>
</template>
<script>
import {getStatusClass} from "@/helpers/common"
import InvoiceActions from "@/views/Invoices/InvoiceActions"

export default {
  name: "invoices-table",
  components: {InvoiceActions},
  props: {
    employeeId: {
      type: String,
    },
    type: {
      type: String,
    },
    title: String,
    columns: {
      type: Array,
      default: () => {
        return [
          {
            key:"invoiceRef",
            label: "Ref"
          },
          {
            key:"employeeId",
            label:"Employee"
          },
          {
            key:"products",
            label:"Shift"
          },
          {
            key:"status",
            label:"Status"
          },
          {
            key: "amount",
            label:"Total"
          },
          {
            key: "createdAt",
            label: "Date Created",
            // sorting: true
          },
          {
            key: "",
            label:"Actions"
          },
        ]
      }
    }
  },
  data() {
    return {
      lastSnapshot: null,
      loading: true,
      listFull: false,
      employees: [],
      loadingProcess: "",
      errorMessage: "",
      sortDirection: "desc",
      sortBy: "createdAt",
      model: {
        statuses: [],
        employee: null
      },
      statuses: [
        {key: "PAID", label: this.$t("PAID")},
        {key: "PENDING", label: this.$t("PENDING")},
        {key: "PENDING_REFUND", label: this.$t("PENDING_REFUND")},
        {key: "REFUNDED", label: this.$t("REFUNDED")},
        {key: "CANCELED", label: this.$t("CANCELED")},
      ],

      tableData: [],
    }
  },
  computed: {
    userRole(){
      return this.$store.state.user.claims.role ?? null
    },
  },
  async mounted() {
    console.log("this.$store.state.user.claims", this.$store.state.user.claims)
    await this.getInvoices()
  },
  methods: {
    // employeeName(id) {
    //   let index = this.employees.findIndex(e => e.id === id)
    //   if (index > -1){
    //       return this.employees[index].name ?? ""
    //   }
    //   return ""
    // },
    getStatusClass,
    updateStatus(id, status){
      let index = this.tableData.findIndex(r => r.id === id)
      if (index >= 0) {
        this.tableData[index]["status"] = status
      }
    },
    async getInvoices(sorter, clear = false) {
      this.loading = true

      if (sorter){
        this.sortBy = sorter.field
        this.sortDirection = sorter.order
      }

      if (clear){
        this.tableData = []
        this.lastSnapshot = null
        this.listFull = false
      }

      if (this.employeeId) {
        this.model.employee = {id: this.employeeId}
      }

      const filters = {
        statuses: this.model.statuses.map(c => c.key) ?? null,
        employee: this.userRole === "EMPLOYEE" ? this.$store.state.user.claims.user_id  : this.model.employee != null ? this.model.employee.id : null
      }

      const sorting = {
        sortBy: this.sortBy,
        sortDirection: this.sortDirection
      }

      await this.$store.dispatch("fetchInvoices", {filters: filters, sorting: sorting, lastSnapshot: this.lastSnapshot}).then(async (result) => {
        this.loading = false
        if (result.data.length === 0) {
          this.listFull = true
          return
        }

        if (result.lastSnapshot !== null) {
          this.lastSnapshot = result.lastSnapshot
        }

        for (const record of result.data) {
          // console.log("record", record)
          if (this.$store.state.user.claims.role === "SYSTEM_USER") {
            const employee = await this.$store.dispatch("fetchEmployee", record.employeeId)
            record.employeeName = employee.firstName + " " + employee.lastName
          }

          let index = this.tableData.findIndex(r => r.id === record.id)
          if (index > -1) {
            this.tableData[index] = record
          } else {
            this.tableData.push(record)
          }
        }

        if (result.data === null || result.data.length < 10) {
          this.listFull = true
        }
      })
    },
  },
}
</script>
<style></style>
