<script>
import InvoicesTable from "@/views/Tables/InvoicesTable.vue"

export default {
  name: "EmployeeInvoices",
  components: {InvoicesTable}
}
</script>

<template>
<invoices-table :employeeId="$route.params.id"></invoices-table>
</template>

<style scoped>

</style>