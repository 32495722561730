<template>
  <div>
    <base-header class="pb-6 pb-8 pt-8 pt-md-5">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="col-xl-8 order-xl-1">
            <card shadow type="secondary">
              <template #header>
                <div class="border-0">
                  <div class="row align-items-center">
                    <div class="col-8 text-start">
                      <h5 v-if="!isUpdate" class="mb-0">{{ $t("New Partner") }}</h5>
                      <h5 v-else-if="userRole === 'EMPLOYEE'" class="mb-0">{{ $t("Update Profile") }}</h5>
                      <h5 v-else class="mb-0">{{ $t("Update Partner") }}</h5>
                    </div>
                  </div>
                </div>
              </template>
              <form>
                <div class="text-start fw-bolder text-uppercase text-muted">{{ $t("Personal Details") }} </div>
                <hr class="mt-0">
                <div class="ps-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                          v-model="model.firstName"
                          alternative=""
                          :label="$t('First name')"
                          :placeholder="$t('First name')"
                          input-classes="form-control-alternative"
                          :error="v$.model.firstName.$errors.map( err => { return err.$message })[0]"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                          v-model="model.lastName"
                          alternative=""
                          :label="$t('Last name')"
                          :placeholder="$t('Last name')"
                          input-classes="form-control-alternative"
                          :error="v$.model.lastName.$errors.map( err => { return err.$message })[0]"
                      />
                    </div>
                  </div>
                  <div class="row">

                    <div class="col-lg-6">
                      <base-input
                          v-model="model.registrationNumber"
                          alternative=""
                          :label="$t('Registration number')"
                          :placeholder="$t('Registration number')"
                          input-classes="form-control-alternative"
                          :error="v$.model.registrationNumber.$errors.map( err => { return err.$message })[0]"
                      />
                    </div>
                    <div class="col-lg-6">
<!--                      :class="{'has-danger': v$.model.startDate.$error }"-->
                      <div class="form-group has-label" >
                        <label class="form-control-label">{{ $t("Registration number expiration date") }}</label>
                        <VueDatePicker
                            v-model="model.registrationExpirationDate"
                            format="dd/MM/yyyy"
                            :enable-time-picker="false"
                            auto-apply
                            :placeholder="$t('Registration number expiration date')"
                            :min-date="new Date()"
                        ></VueDatePicker>
<!--                        <div v-if="v$.model.startDate.$invalid" class="text-danger invalid-feedback" style="display: block;">-->
<!--                          {{ v$.model.startDate.$errors.map( err => { return err.$message })[0] }}-->
<!--                        </div>-->
                      </div>
                    </div>
                    <div class="col-lg-6">
                    <base-input
                        v-model="model.idCard"
                        alternative=""
                        :label="$t('Identity card number')"
                        :placeholder="$t('Identity card number')"
                        input-classes="form-control-alternative"
                        :error="v$.model.idCard.$errors.map( err => { return err.$message })[0]"
                    />
                  </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group has-label">
                        <label class="form-control-label float-start">{{ $t("Phone number") }}</label>
                        <base-input
                            v-model="model.phoneNumber"
                            type="number"
                            formClasses="input-group-alternative mb-0 phone-input"
                            style="display: flex;border-radius: 0;"
                            :placeholder="$t('Phone number')"
                            :error="v$.model.phoneNumber.$errors.map( err => { return err.$message })[0]"
                        >
                          <template #addonLeft>
                            <select v-model="model.phoneNumberCode" class="form-control form-control-alternative" style="padding: 0;height: 23px;border: none !important;">
                              <option :key="country.code" v-for="country in countryCodes" :value="country.code"> {{ isWindows ? '' : isoCountryCodeToFlagEmoji(country.iso)
                                }} {{ country.iso }} {{ country.code }}</option>
                            </select>
                          </template>
                          <template #infoBlock>
                            <span class="text-sm text-muted w-100">{{ $t("Will be used to send verification code for login.") }}</span>

                          </template>
                        </base-input>

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group has-label">
                        <label class="form-control-label">{{ $t("Preferred language") }} </label>
                        <multiselect
                            v-model="model.lang"
                            :options="['en', 'el']"
                            :placeholder="$t('Select language')"
                            :show-labels="false"
                            required
                            class=""
                        >
                          <template #singleLabel="{ option }">{{ $t(option) }}</template>
                          <template #option="props" >
                            {{ $t(props.option) }}
                          </template>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <base-input
                          v-model="model.bankName"
                          alternative=""
                          :label="$t('Bank name')"
                          :placeholder="$t('Bank name')"
                          input-classes="form-control-alternative"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                          v-model="model.tiNumber"
                          alternative=""
                          :label="$t('Tax identification number')"
                          :placeholder="$t('Tax identification number')"
                          input-classes="form-control-alternative"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                          v-model="model.iban"
                          alternative=""
                          :label="$t('IBAN')"
                          :placeholder="$t('IBAN')"
                          input-classes="form-control-alternative"
                          :error="v$.model.iban.$errors.map( err => { return err.$message })[0]"
                      />
                    </div>
                    <div  v-if="userRole === 'SYSTEM_USER'" class="col-lg-6">
                      <div class="form-group has-label">
                        <label class="form-control-label">{{ $t("Status") }} </label>
                        <multiselect
                            v-model="model.status"
                            :show-labels="false"
                            :placeholder="$t('Select status')"
                            :options="statuses">
                          <template #singleLabel="{ option }">{{ $t(option) }}</template>
                          <template #option="props" >
                            {{ $t(props.option) }}
                          </template>
                        </multiselect>
                      </div>
                  </div>
                    <div  v-if="userRole === 'SYSTEM_USER' && model.status === 'RESTRICTED'" class="col-lg-6">
                      <div class="form-group has-label">
                        <label class="form-control-label">{{ $t("Restricted reason") }} </label>
                        <multiselect
                            v-model="model.restrictedReasonType"
                            :show-labels="false"
                            :placeholder="$t('Select restricted reason')"
                            :options="restrictedReasons">
                          <template #singleLabel="{ option }">{{ $t(option) }}</template>
                          <template #option="props" >
                            {{ $t(props.option) }}
                          </template>
                        </multiselect>
                      </div>
                    </div>
                    </div>
                </div>
                <div class="text-start fw-bolder text-uppercase text-muted">{{ $t("Contact details") }} </div>
                <hr class="mt-0">
                <div class="ps-lg-4">
                  <div class="row">
                    <base-input
                        v-model="model.email"
                        alternative=""
                        :label="$t('Contact email')"
                        :placeholder="$t('Contact email')"
                        input-classes="form-control-alternative"
                        :error="v$.model.email.$errors.map( err => { return err.$message })[0]"
                    />
                  </div>
                </div>
                <div class="text-start fw-bolder text-uppercase text-muted">{{ $t("Job Positions") }} </div>
                <hr class="mt-0">
                <div class="ps-lg-4">
                  <div class="row">
                    <div v-if="userRole === 'SYSTEM_USER'" class="mb-3 text-start" :class="{'has-danger': v$.model.positionIds.$error }">
                      <multiselect
                          v-model="model.positionIds"
                          :show-labels="false"
                          label="value"
                          track-by="key"
                          :close-on-select="false"
                          :placeholder="$t('Select related positions')"
                          :options="positions" :multiple="true"

                          ></multiselect>
<!--                      <multiselect-->
<!--                          v-model="model.destination"-->
<!--                          :options="destinationOptions"-->
<!--                          :placeholder="$t('Select Destination')"-->
<!--                          :show-labels="false"-->
<!--                          label="value"-->
<!--                          track-by="key"-->
<!--                      ></multiselect>-->
                      <div v-if="v$.model.positionIds.$invalid" class="text-danger invalid-feedback" style="display: block;">
                        {{ v$.model.positionIds.$errors.map( err => { return err.$message })[0] }}
                      </div>
                    </div>
                    <div v-else>
                      <div class="text-start pb-3">
                        <div v-for="item in model.positionIds" :key="item" class="badge bg-gradient-primary m-1 p-1 font-size-md"> {{ item.value }}</div>
                      </div>
                      <span class="text-sm text-muted w-100 text-start float-start pb-3">
                        {{ $t("In order to change your positions contact our support.") }}
                      </span>

                    </div>
                  </div>
                </div>
                <div class="text-start fw-bolder text-uppercase text-muted">{{ $t("Address") }} </div>
                <hr class="mt-0">
                <div class="ps-lg-4">

                  <div class="row">
                    <div class="col-md-6">
                      <base-input
                          v-model="address.street"
                          alternative=""
                          :label="$t('Street')"
                          :placeholder="$t('Home Address')"
                          input-classes="form-control-alternative"
                      />

                    </div>
                    <div class="col-md-6">
                      <base-input
                          v-model="address.street2"
                          alternative=""
                          :label="$t('Street 2')"
                          :placeholder="$t('Home Address 2')"
                          input-classes="form-control-alternative"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
<!--                      :class="{'has-danger': v$.address.city.$error }"-->
                      <div class="form-group has-label">
                        <label class="form-control-label">{{ $t('City') }}</label>

                        <multiselect
                            v-model="address.city"
                            :show-labels="false"
                            :deselect-label="$t('Cannot remove this value')"
                            :placeholder="$t('Select city')"
                            :options="cities"
                            :searchable="false"
                            :custom-label="customCityLabel"
                        >
                        </multiselect>

<!--                        <div v-if="v$.address.city.$error" class="text-danger invalid-feedback" style="display: block;">-->
<!--                          {{ v$.address.city.$errors.map( err => { return err.$message })[0] }}-->
<!--                        </div>-->
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <base-input
                          v-model="address.country"
                          disabled="true"
                          alternative=""
                          :label="$t('Country')"
                          :placeholder="$t('Country')"
                          input-classes="form-control-alternative"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input
                          v-model="address.postalCode"
                          alternative=""
                          :label="$t('Postal code')"
                          :placeholder="$t('Postal code')"
                          input-classes="form-control-alternative"
                      />
                    </div>
                  </div>
                </div>
                <template v-if="userRole === 'SYSTEM_USER'">
                <div class="text-start fw-bolder text-uppercase text-muted">{{ $t("Notes") }} </div>
                <hr class="mt-0">
                <div class="ps-lg-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <base-input alternative="">
                        <textarea
                            v-model="model.description"
                            rows="4"
                            class="form-control form-control-alternative"
                            :placeholder="$t('A few private notes about the partner')"
                        >
                          </textarea>
                      </base-input>
                    </div>
                  </div>
                </div>
              </template>
<!--                <div class="text-start fw-bolder text-uppercase text-muted">Documents </div>-->
<!--                <hr class="mt-0">-->
<!--                <div class="ps-lg-4">-->
<!--                  <div class="row"></div>-->
<!--                </div>-->
                <div class="col-12 text-end">
                  <base-button v-if="!isUpdate" class="me-2" outline type="info" @click="clearForm">{{ $t("Reset") }}</base-button>
                  <submit-button :alert-message="errorMessage" :state="loadingProcess" @clicked="submitEmployee">{{ $t(isUpdate ? 'Update' : 'Submit') }}</submit-button>
                </div>
              </form>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"
import useVuelidate from "@vuelidate/core"
import {helpers, required, email, requiredIf, minLength} from "@vuelidate/validators"
import {getCountries, getCountryCallingCode, isValidNumber, parsePhoneNumber} from "libphonenumber-js"
import {isoCountryCodeToFlagEmoji, isWindows} from "@/helpers/common"
import {isValidIBANNumber} from "@/helpers/IbanHelper"
const isValidPhone = (param) => helpers.withParams(
    { type: "isValidPhone", value: param }, (value) => {

      if (value == null || value === ""){
        return true
      }
      return isValidNumber(param+value)
    })
const isValidIban = () => helpers.withParams(
    { type: "isValidIBAN" },
    (value) => {
      if (value == null || value === ""){
        return true
      }
      return isValidIBANNumber(value)
    }
)

export default {
  name: "newEmployee",
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      isWindows,
      employeeId: null,
      countryCodes: [],
      positions: [],
      isUpdate: false,
      errorMessage: "",
      loadingProcess: "",
      loadingMessage: "",
      statuses: [
        "VERIFIED",
        "PENDING",
        "RESTRICTED",
        "DISABLED"
      ],
      restrictedReasons:[
        "LICENCE_EXPIRED",
        "INVOICE_OVERDUE"
      ],
      dbMobilePhone: "",
      cities: ["LIMASSOL", "NICOSIA", "LARNACA", "PAPHOS", "FAMAGUSTA"],
      model: {
        idCard: "",
        registrationNumber: "",
        registrationExpirationDate: null,
        lang: "en",
        phoneNumber: "",
        phoneNumberCode: "+357",
        firstName: "",
        lastName: "",
        iban: "",
        bankName: "",
        tiNumber: "",
        email: "",
        description: "",
        positionIds: [],
        status: "VERIFIED",
        restrictedReasonType: ""
      },
      address: {
        country: "Cyprus",
        city: null,
        street: null,
        street2: null,
        postalCode: null,
      }
    }
  },
  validations () {
    return {
      model: {
        positionIds: {
          requiredAtLeastOne: helpers.withMessage(this.$t("You need to select at least one."), required)
        },
        phoneNumber: {
          required,
          validated: helpers.withMessage(this.$t("Must be valid phone number."), isValidPhone(this.model.phoneNumberCode))
        },
        iban: {
          validated: helpers.withMessage(this.$t("Must be valid IBAN number."), isValidIban(this.model.iban))
        },
        firstName: {
          required: helpers.withMessage(this.$t("Value is required"), required)
        },
        lastName: {
          required: helpers.withMessage(this.$t("Value is required"), required)
        },
        registrationNumber: {
          required: helpers.withMessage(this.$t("Value is required"), required)
        },
        idCard: {
          required: helpers.withMessage(this.$t("Value is required"), required),
          minLength: minLength(6)
        },
        email: {
          email: email,
          required: helpers.withMessage(this.$t("Value is required"), required)
        }
      },
      // address: {
      //   street: {
      //     required
      //   },
      //   city: {
      //     required
      //   },
      //   countryCode: {
      //     required
      //   },
      //   postalCode: {
      //     required,
      //   },
      // },
    }
  },
  computed: {
    userRole(){
      return this.$store.state.user.claims.role ?? null
    },
  },
  async mounted() {

    if (this.userRole === "SYSTEM_USER"){
      this.employeeId = this.$route.params.id ?? null
    } else if (this.userRole === "EMPLOYEE"){
      this.employeeId = this.$store.state.user.claims.user_id
    }

    console.log("employeeId", this.employeeId)
    await this.getPositions()
    if (this.employeeId !== null) {
      this.isUpdate = true
      await this.getEmployee()
      await this.getEmployeePrivateInfo()
    }

    let countries = getCountries()

    countries.forEach( country => {
      let code = getCountryCallingCode(country)
      this.countryCodes.push({code: "+" + code, text: country + " +" + code, iso: country})
    })
  },
  methods: {
    isoCountryCodeToFlagEmoji,
    async getPositions() {
      await this.$store.dispatch("fetchAllPositions").then((data) => {
        console.log("dataaa", data)
        this.positions = data.map( p => { return {key: p.id, value: p.name}})
      })
    },
    customCityLabel (option) {
      return this.$t(option)
    },
    async getEmployee() {
      const data = await this.$store.dispatch("fetchEmployee", this.employeeId)

      let selectedPositions = []
      data.positionIds.forEach( positionsId => {
        let selected = this.positions.find(pos => pos.key === positionsId )
        if (selected) {
          selectedPositions.push(selected)
        }
      })

      this.model = data
      this.model.positionIds = selectedPositions
      if (data.hasOwnProperty("registrationExpirationDate") && data.registrationExpirationDate !== null) {
        this.model.registrationExpirationDate = data.registrationExpirationDate.toDate()
      }
    },
    async getEmployeePrivateInfo(){
      let res = await this.$store.dispatch("fetchEmployeePrivateInfo", this.employeeId)
      this.address = res.address
      this.model.email = res.email
      this.model.lang = res.lang
      this.model.registrationNumber = res.hasOwnProperty("registrationNumber") ? res.registrationNumber : ""
      this.model.idCard = res.hasOwnProperty("idCard") ? res.idCard : ""
      this.model.iban = res.iban
      this.model.bankName = res.bankName
      this.model.tiNumber = res.tiNumber
      this.dbmMobilePhone = res.phoneNumber
      let phoneDetails = parsePhoneNumber(res.phoneNumber)
      this.model.phoneNumber = phoneDetails.nationalNumber
      this.model.phoneNumberCode = "+"+phoneDetails.countryCallingCode

    },
    async submitEmployee() {
      let self = this

      this.v$.$touch()

      if (this.v$.model.$invalid) {
        console.log(this.v$.$errors)
        return
      }

      let payload = JSON.parse(JSON.stringify(this.model))
      let positionsArray = []
      for (let i=0;i < payload.positionIds.length; i++){
        positionsArray.push(payload.positionIds[i].key)
      }
      payload.positionIds = positionsArray
      payload.address = JSON.parse(JSON.stringify(this.address))
      payload.address.countryCode = "CY"
      delete payload.address.country

      if (payload.iban) {
        payload.iban.replace(/\s/g, "")
      }
      if (this.isUpdate) {
        payload.employeeId = payload.id
        delete payload.id
      }

      payload.phoneNumber = payload.phoneNumberCode + payload.phoneNumber
      delete payload.phoneNumberCode

      if (this.dbmMobilePhone === payload.phoneNumber ){
        delete payload.phoneNumber
      }

      delete payload.queries
      delete payload.createdAt
      delete payload.updatedAt
      delete payload.updatedBy
      // console.log("payload", payload)


      if (payload.restrictedReasonType === "" ) {
        delete payload.restrictedReasonType
      }

      this.loadingProcess = "processing"

      FirebaseFunctionsHelper.callFunction(
          this.isUpdate ? "employee-update" : "employee-create",
          // eslint-disable-next-line no-undef
          payload
      )
          .then((result) => {
            // console.log("res", result)
            // eslint-disable-next-line no-prototype-builtins
            if (result != null && result.hasOwnProperty("message")) {
              // console.log("result message", result.message)
              self.loadingProcess = "failed"
              self.errorMessage = result.message
              return
            }
            // console.log("submitUser result", result)
            this.loadingProcess = "done"

            // if (this.$store.state.user.claims.role === "SYSTEM_USER") {
            // }
            if (!this.isUpdate) {
              this.$router.push({path: "/employees"})
            } else {
              this.$router.push({path: this.$store.state.user.claims.role === "SYSTEM_USER" ? "/employees/" + self.$route.params.id : "/profile"})
            }
          })
          .catch((error) => {
            self.loadingProcess = "failed"
            self.errorMessage = error.message
            console.log("submitUser error", error)
          })

      // setTimeout(() => {
      //   this.loadingProcess = "failed";
      //   this.loadingMessage = "There was an error.";
      //   //Add Tokens
      // }, 2000);
    },
    clearForm() {
      this.loadingProcess = ""
      if (this.isUpdate){
        this.model = this.dbUser
      } else {
        this.model = {
          phoneNumber: "",
          firstName: "",
          lastName: "",
          email: "",
          description: "",
          positionIds: [],
        }

        this.address = {
          country: "Cyprus",
          city: null,
          street: null,
          street2: null,
          postalCode: null,
        }
      }
    },
  },
}
</script>
<style scoped>
.form-group.has-label {
  padding-top: 0.5rem !important;
}
</style>
