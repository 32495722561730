<template>
  <div>
    <card shadow type="secondary">
      <template #header>
        <div class="bg-white border-0">
          <div class="row align-items-center">
            <div class="col-8 text-start">
              <h5 class="mb-0">{{ $t("Filter by") }}</h5>
            </div>
            <!--              <div class="col-4 text-end">-->
            <!--                <base-button tag="a" :href="'/employees/'+$route.params.id+'/edit'" type="primary" class="btn btn-sm btn-primary">-->
            <!--                  Edit-->
            <!--                </base-button>-->
            <!--              </div>-->
          </div>
        </div>
      </template>

      <div class="row text-start">
        <div class="col-md-4">
          <div class="form-group has-label">
            <label class="form-label">{{ $t("Name") }}</label>
            <base-input
                v-model="filters.name"
                type="text"
                form-classes="input-group-alternative mb-2"
                :placeholder="$t('Name')"
                :error="v$.filters.name.$errors.map( err => { return err.$message })[0]"
            ></base-input>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group has-label">
            <label class="form-label">{{ $t("Status") }}</label>
            <multiselect
                v-model="filters.status"
                :show-labels="false"
                label="label"
                track-by="key"
                :placeholder="$t('Select status')"
                :options="statuses"
                :multiple="false"
            >
            </multiselect>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group has-label">
            <label class="form-label">{{ $t("Position") }}</label>
            <multiselect
                v-model="filters.position"
                :show-labels="false"
                label="name"
                track-by="id"
                :placeholder="$t('Select position')"
                :options="positions"
                :multiple="false"
            >
            </multiselect>
          </div>
        </div>
      </div>

      <template #footer>
        <div class="col float-end">
          <submit-button
              :alert-message="errorMessage"
              :state="loadingProcess"
              @clicked="getEmployees(true)">
            {{ $t("Search") }}
          </submit-button>
        </div>
      </template>

    </card>
    <br>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0 text-start" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h5>
        </div>
        <div class="col text-end">
          <base-button tag="a" href="/employees/new" type="primary" size="sm">{{ $t("Add New") }}</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush text-start"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
        :loading="loading"
        :paginated="true"
        :list-full="listFull"
        @sort="fetchMore"
        @fetch-more="fetchMore"
      >
        <template #columns>
          <th>{{ $t("Name") }}</th>
          <th>{{ $t("Rating") }}</th>
          <th>{{ $t("Status") }}</th>
          <th>{{ $t("Reg. expiration date") }}</th>
          <th>{{ $t("Date Created") }}</th>
          <th></th>
        </template>

        <template #default="row">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <router-link :to="'/employees/' + row.item.id + '/info'">
                  <span class="name mb-0 text-sm fw-bolder">
                  {{ row.item.firstName }} {{ row.item.lastName }}
                  </span>
                </router-link>
              </div>
            </div>
          </th>
          <th scope="row">
            <rating-box :rating-score="row.item.ratingScore" :rating-count="row.item.ratingCount"></rating-box>
          </th>
          <td>
            <div class="badge p-1 font-size-xs" :class="getStatusClass(row.item.status)">
              <span class="status ">{{ $t(row.item.status) }}</span>
            </div>
            <br>
            <span v-if="row.item.status === 'RESTRICTED'" class="fw-bold">{{ $t(row.item.restrictedReasonType ?? "") }} </span>
          </td>
          <td>
            <div class="d-flex align-items-center">
              <div
                  class="name mb-0 text-md-left font-size-xs fw-bold"
                  :class="{'text-danger': !row.item.registrationExpirationDate?.isAfter(new Date())}"
              >
                {{ row.item.registrationExpirationDate?.format("DD/MM/YYYY") }}</div>
            </div>
          </td>
          <td>
            <div class="d-flex align-items-center">
              <!--              <span class="completion mr-2">{{ row.item.completion }}%</span>-->
              <!--              <div>-->
              <!--                <base-progress-->
              <!--                  :type="row.item.statusType"-->
              <!--                  :show-percentage="false"-->
              <!--                  class="pt-0"-->
              <!--                  :value="row.item.completion"-->
              <!--                />-->
              <!--              </div>-->
              <div class="name mb-0 text-md-left font-size-xs">{{ row.item.createdAt }}</div>
            </div>
          </td>

          <td class="text-end">
            <base-button
              tag="a"
              type="primary"
              :href="'/employees/' + row.item.id"
              class="border-0 me-2 text-white"
              size="md"
              >
              {{ $t("View") }}
            </base-button>
            <base-button
                type="danger"
                class="border-0"
                size="md"
                @click="deleteEmployee(row.item.id)"
            >
              {{ $t("Delete") }}
            </base-button>
          </td>
        </template>

        <template #footer>
        </template>
      </base-table>
    </div>

    <!--    <div-->
    <!--      class="card-footer d-flex justify-content-end"-->
    <!--      :class="type === 'dark' ? 'bg-transparent' : ''"-->
    <!--    >-->
    <!--      <base-pagination total="30"></base-pagination>-->
    <!--    </div>-->
  </div>
  </div>
</template>
<script>
import BaseButton from "@/components/BaseButton"
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"
import {getStatusClass} from "@/helpers/common"
import SubmitButton from "@/components/SubmitButton.vue"
import BaseInput from "@/components/BaseInput.vue"
import {minLength} from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import RatingBox from "@/components/RatingBox.vue"
export default {
  name: "employees-table",
  components: {RatingBox, BaseInput, SubmitButton, BaseButton},
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      listFull: false,
      lastSnapshot: null,
      loading: true,
      positions: [],
      tableData: [],
      loadingProcess: "",
      errorMessage: "",
      filters: {
        position: null,
        status: null,
        name: null
      },
      statuses: [
        {key: "VERIFIED", label: this.$t("VERIFIED")},
        {key: "PENDING", label: this.$t("PENDING")},
        {key: "RESTRICTED", label: this.$t("RESTRICTED")},
        {key: "DISABLED", label: this.$t("DISABLED")},
      ],
    }
  },
  validations () {
    return {
      filters: {
        name: {
          minlength: minLength(3)
        },
      }
    }
  },
  async mounted() {
    await this.getEmployees()
    this.positions = await this.$store.dispatch("fetchAllPositions")
    console.log("positions", this.positions)
  },
  methods: {
    getStatusClass,
    async fetchMore() {
      this.loading = true
      await this.getEmployees()
    },
    deleteEmployee(id) {
      this.$swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete employee!",
        confirmButtonColor: "#dc3545",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let employeeIndex = this.tableData.findIndex(e => e.id === id)
          if (employeeIndex > -1){
            this.tableData.splice(employeeIndex, 1)
          }
          FirebaseFunctionsHelper.callFunction("employee-remove", {employeeId: id})
              .then((result) => {
                console.log("res", result)
                // eslint-disable-next-line no-prototype-builtins
                if (result != null && result.hasOwnProperty("message")) {
                  console.log("resmessage", result.message)
                  this.loadingProcess = "failed"
                  this.errorMessage = result.message
                  this.$swal.fire({
                    icon: "error",
                    html: result.message,
                    title: "Something went wrong!",
                  })

                } else {
                  this.$swal.fire({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    icon: "success",
                    title: "Employee deleted",
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", this.$swal.stopTimer)
                      toast.addEventListener("mouseleave", this.$swal.resumeTimer)
                    }
                  })
                }

                this.getEmployees()
              }, this)
              .catch((error) => {
                console.error("submitUser error", error)
                this.$swal.fire({
                  icon: "error",
                  html: result.message,
                  title: "Something went wrong!",
                })
              })

        }
      })
    },
    async getEmployees(clear = false) {
      // if (
      //   this.$store.state.system.companies.length > 0 &&
      //   this.$store.getters.isCacheExpired
      // ) {
      //   this.tableData = this.$store.state.system.companies
      //   return
      // }

      this.v$.$touch()

      if (this.v$.filters.$invalid) {
        console.log(this.v$.$errors)
        return
      }

      if (clear){
        this.tableData = []
        this.lastSnapshot = null
        this.listFull = false
      }
    console.log("this.filters.status", this.filters)
      await this.$store.dispatch("fetchEmployees", {name: this.filters.name, position: this.filters.position ? this.filters.position.id : null, status: this.filters.status != null ? this.filters.status["key"] : null, lastSnapshot: this.lastSnapshot}).then((result) => {
        console.log("fetchEmployees", result)
        this.loading = false

        if (result.data.length === 0){
          this.listFull = true
          return
        }

        result.data.forEach ( record => {

          let index = this.tableData.findIndex(r => r.id === record.id)
          if (index > -1){
            this.tableData[index] = record
          } else {
            this.tableData.push(record)
          }
        })

        if (result.lastSnapshot !== null){
          this.lastSnapshot = result.lastSnapshot
        }

        if (result.data === null || result.data.length < 10){
          this.listFull = true
        }
      })
    },
  },
}
</script>
<style></style>
